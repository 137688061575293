<template>
  <div class="">
    <div class="px-3">
      <div class="image-upload">
      <div>
        <div class="outer" style="position: relative">
          <figure
            class="figureClass"
            ref="figureBlock"
            @mousemove="handleMouseMove"
            @click="getPosition"
          >
            <img
              :src="getImage()"
              :key="getImage()"
              :class="[`pic-${order}`]"
              class="curs"
              :style="getFlipImageCss()"
            />

            <div class="focalPointHover"></div>
          </figure>
          <div
            class="focal-point-picker"
            :style="`top: ${getYCoordinate}%;
    left: ${getXCoordinate}%;`"
          >
            <div class="picketDot"></div>
          </div>
        </div>
      </div>
    </div>
      <ImageFileComp
          :selectedImageRoot="getSelectedImageSrc"
          @selectedImageCallback="selectedImage($event)"
        />
    </div>
  <hr>
  <div class="px-3">
    <label for="button-text" class="editor-label">Image Position:</label>
    <div class="d-flex gap text-align-section">
        <label title="Left" for="left" @click="setImageAlign('left')" :class="getImageAlign=='left'?'active-editor-align':''" class="editor-text-left-control">
          <svg v-if="isDesktopView" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6667 0.666626H2.00008C1.64646 0.666626 1.30732 0.807102 1.05727 1.05715C0.807224 1.3072 0.666748 1.64634 0.666748 1.99996V9.99996C0.666748 10.3536 0.807224 10.6927 1.05727 10.9428C1.30732 11.1928 1.64646 11.3333 2.00008 11.3333H12.6667C13.0204 11.3333 13.3595 11.1928 13.6096 10.9428C13.8596 10.6927 14.0001 10.3536 14.0001 9.99996V1.99996C14.0001 1.64634 13.8596 1.3072 13.6096 1.05715C13.3595 0.807102 13.0204 0.666626 12.6667 0.666626ZM12.6667 9.99996H5.33341V1.99996H12.6667V9.99996Z" fill="currentColor"/>
</svg>
          <svg v-else style=" transform: rotate(90deg);" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6667 0.666626H2.00008C1.64646 0.666626 1.30732 0.807102 1.05727 1.05715C0.807224 1.3072 0.666748 1.64634 0.666748 1.99996V9.99996C0.666748 10.3536 0.807224 10.6927 1.05727 10.9428C1.30732 11.1928 1.64646 11.3333 2.00008 11.3333H12.6667C13.0204 11.3333 13.3595 11.1928 13.6096 10.9428C13.8596 10.6927 14.0001 10.3536 14.0001 9.99996V1.99996C14.0001 1.64634 13.8596 1.3072 13.6096 1.05715C13.3595 0.807102 13.0204 0.666626 12.6667 0.666626ZM12.6667 9.99996H5.33341V1.99996H12.6667V9.99996Z" fill="currentColor"/>
</svg>
<span class="ml-1 select-position-span">{{ isDesktopView ? 'Left':'Up' }}</span>

          </label>
          
          <label v-if="isDesktopView" title="Right" for=""  @click="setImageAlign('right')" :class="getImageAlign=='right'?'active-editor-align':''"  class="editor-text-center-control">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8335 2.66663H3.16683C2.81321 2.66663 2.47407 2.8071 2.22402 3.05715C1.97397 3.3072 1.8335 3.64634 1.8335 3.99996V12C1.8335 12.3536 1.97397 12.6927 2.22402 12.9428C2.47407 13.1928 2.81321 13.3333 3.16683 13.3333H13.8335C14.1871 13.3333 14.5263 13.1928 14.7763 12.9428C15.0264 12.6927 15.1668 12.3536 15.1668 12V3.99996C15.1668 3.64634 15.0264 3.3072 14.7763 3.05715C14.5263 2.8071 14.1871 2.66663 13.8335 2.66663ZM10.5002 12H3.16683V3.99996H10.5002V12Z" fill="currentColor"/>
</svg>
<span class="ml-1 select-position-span">Right</span>
          </label>
          <label for="end" title="Background" @click="setImageAlign('no')" :class="getImageAlign=='no'?'active-editor-align':''" class="editor-text-right-control" style="white-space: nowrap;">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 2.66663H2.83333C2.47971 2.66663 2.14057 2.8071 1.89052 3.05715C1.64048 3.3072 1.5 3.64634 1.5 3.99996V12C1.5 12.3536 1.64048 12.6927 1.89052 12.9428C2.14057 13.1928 2.47971 13.3333 2.83333 13.3333H13.5C13.8536 13.3333 14.1928 13.1928 14.4428 12.9428C14.6929 12.6927 14.8333 12.3536 14.8333 12V3.99996C14.8333 3.64634 14.6929 3.3072 14.4428 3.05715C14.1928 2.8071 13.8536 2.66663 13.5 2.66663ZM13.5 12H2.83333V3.99996H13.5V12Z" fill="currentColor"/>
<rect x="3.5" y="4.66663" width="9.33333" height="6.66667" fill="currentColor"/>
</svg>
<span class="ml-1 select-position-span">BG</span>
          </label>
       </div>
       <div class=""  v-if="
          pageDesign.pageStyles.imageAlign !== 'no' &&
          Object.prototype.hasOwnProperty.call(
            pageDesign.imageBlock,
            `useImageVariableWidth`
          )
        ">
       <div class="mt-2" v-if="isDesktopView" title="Width %">
        <label for="button-text" class="editor-label">Width %:</label>
       <div class="form-group d-flex align-items-center">
              <input
                type="range"
                class="form-control-range formControlRange"
                min="20"
                max="50"
                :key="count + 'desktop'"
                v-model="pageDesign.imageBlock.imageVariableWidth"
              />

              <span class="ml-3">{{
                pageDesign.imageBlock.imageVariableWidth
              }}</span>
            </div>
       </div>
       <div class="mt-2" v-else title="Height %">
        <label for="button-text" class="editor-label">Height %: (Mobile)</label>
       <div class="form-group d-flex align-items-center">
              <input
                type="range"
                class="form-control-range formControlRange"
                min="20"
                max="50"
                :key="count + 'desktop'"
                v-model="pageDesign.imageBlock.imageVariableMobileHeight"
              />

              <span class="ml-3">{{
                pageDesign.imageBlock.imageVariableMobileHeight
              }}</span>
            </div>
       </div>
      </div>
  </div>
<hr>
<div class="px-3">
  <div class="d-flex align-items-center justify-content-between">
    <label for="button-text" class="editor-label">Flip Image:</label>
  <div class="text-align-section" style=" border: 1px solid #d2d8e0!important;">
  <label @click="
              pageDesign.imageBlock.style.flipImage =
                !pageDesign.imageBlock.style.flipImage
            " for="end" title="Flip"  class="editor-text-right-control" :class="pageDesign.imageBlock.style.flipImage?'active-editor-align':''"   style="white-space: nowrap; cursor: pointer;">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8H14V14H4V8Z" fill="currentColor" stroke="currentColor" stroke-width="1.33333" stroke-linejoin="round"/>
<path d="M2 2.66669V5.66669H5" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.825 4.433C11.7317 2.95667 9.97767 2 8 2C6.05967 2 4.33433 2.921 3.23767 4.35L2 5.66667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </label>
        </div>
        </div>
        <div class="d-flex mt-2 align-items-center justify-content-between">
    <label for="button-text" class="editor-label">Opacity:</label>
    <div class="editor-select-color" style="width: 25%;" title="Border Radius">
      <svg
          width="18"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
            fill="#B3AFB6"
          />
        </svg>
          <input 
            type="number" 
            min="0"
            v-model.number="pageDesign.imageBlock.style.imageOpacity" 
            class="editor-color-input border-radius w-100" 
            name="borderRadius"
          />
        </div>
        </div>
        <div  v-if="(pageDesign.pageStyles.imageAlign == 'no')||(pageDesign.pageStyles.onlyBackgroundImg && pageDesign.pageType == 'resultPage')"   class="d-flex mt-2 align-items-center justify-content-between">
    <label for="button-text" class="editor-label">Remove Background: </label>
    <div class="text-align-section" style=" border: 1px solid #d2d8e0!important;">  
  <label v-if="getSelectedImageSrc !== ''"  @click="removeBackgroundImage"
             for="end" title="Remove Background"  class="editor-text-right-control delete-control"  style="white-space: nowrap; cursor: pointer;">
<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.99999 13.8333C0.99999 14.2754 1.17558 14.6993 1.48815 15.0118C1.80071 15.3244 2.22463 15.5 2.66666 15.5H9.33332C9.77535 15.5 10.1993 15.3244 10.5118 15.0118C10.8244 14.6993 11 14.2754 11 13.8333V3.83333H0.99999V13.8333ZM2.66666 5.5H9.33332V13.8333H2.66666V5.5ZM8.91666 1.33333L8.08332 0.5H3.91666L3.08332 1.33333H0.166656V3H11.8333V1.33333H8.91666Z" fill="#ED4A4B"/>
</svg>

          </label>
        </div>
        </div>
</div>
<hr>
<div class="px-3">
 <div class="d-flex justify-content-between">
  <label for="button-text" class="editor-label">Use separate image for mobile:</label>
  <b-form-checkbox
        :id="`add-mobile-image-${Date.now()}`"
           v-model="pageDesign.imageBlock.useMobileImage"
                  ></b-form-checkbox>
 </div>
 <div v-if="
        Object.prototype.hasOwnProperty.call(
          pageDesign.pageStyles,
          `useMobileImageAlign`
        ) && !isDesktopView" class="d-flex mt-2 justify-content-between">
  <label for="button-text" class="editor-label">Use separate Position for Mobile:</label>
  <b-form-checkbox
        :id="`mobile=separate-image-${Date.now()}`"
           v-model="pageDesign.pageStyles.useMobileImageAlign"
                  ></b-form-checkbox>
 </div>
 
</div>
<div  style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
  <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Display</div>
</div>
<div class="px-3">
 
        <div class="d-flex mt-2 justify-content-between">
  <label for="button-text" class="editor-label">Desktop:</label>
  <b-form-checkbox
        :id="`display-desktop-image-${Date.now()}`"
           v-model="pageDesign.imageBlock.style.displayDesktop"
                  ></b-form-checkbox>
 </div>
 <div class="d-flex mt-2 justify-content-between">
  <label for="button-text" class="editor-label">Mobile:</label>
  <b-form-checkbox
        :id="`display-mobile-image-${Date.now()}`"
           v-model="pageDesign.imageBlock.style.displayMobile"
                  ></b-form-checkbox>
 </div>
</div>
</div>
</template>

<script>
import ImageFileComp from "../../ImageFileComp.vue";
export default {
  props: {
    pageDesign: Object,
    order: Number,
    isDesktopView: Boolean,
  },
  components: {
    ImageFileComp,
  },
  data() {
    return {
      CurrImage: "",
      tempValueX: null,
      tempValueY: null,
      count: 0,
    };
  },
  computed: {
    getSelectedImageSrc() {
      let image;

      if (
        this.pageDesign.pageType &&
        this.pageDesign.pageType == "noResultPage" &&
        this.pageDesign.imageBlock.noResultSrc !== ""
      ) {
        if (this.isDesktopView) {
          image = this.pageDesign.imageBlock.noResultSrc;
        } else {
          image = this.pageDesign.imageBlock.useMobileImage
            ? this.pageDesign.imageBlock.useMobileImageSrc
            : this.pageDesign.imageBlock.noResultSrc;
        }
      } else {
        if (this.isDesktopView) {
          image = this.pageDesign.imageBlock.src;
        } else {
          image = this.pageDesign.imageBlock.useMobileImage
            ? this.pageDesign.imageBlock.useMobileImageSrc
            : this.pageDesign.imageBlock.src;
        }
      }

      return image == '/images/default_start_page.png' ? 'https://images.quizell.com/website/default_start_page.png'  : image;
    },

    isResultPageType() {
      if (Object.prototype.hasOwnProperty.call(this.pageDesign, "pageType"))
        return true;
      return false;
    },

    getXCoordinate() {
      if (this.pageDesign.imageBlock.style.xCord) {
        return this.pageDesign.imageBlock.style.xCord;
      } else {
        return 50;
      }
    },
    getYCoordinate() {
      if (this.pageDesign.imageBlock.style.yCord) {
        return this.pageDesign.imageBlock.style.yCord;
      } else {
        return 50;
      }
    },
    isSeperateMobileImage() {
      return !this.isDesktopView && this.pageDesign.imageBlock.useMobileImage;
    },

    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
  },
  methods: {
    getFlipImageCss() {
      return this.pageDesign.imageBlock.style?.flipImage
        ? "transform:scaleX(-1)"
        : null;
    },
    getImage() {
      let baseURL = process.env.VUE_APP_API_BASE_URL;
      let selectedImageSrc = this.getSelectedImageSrc;

      // If no image is selected, set a default image
      if (!selectedImageSrc) {
        this.CurrImage = 'https://images.quizell.com/website/default_start_page.png';
      } else {
        // Check if the image source is a full URL or a relative path
        if (
          selectedImageSrc.startsWith("http://") ||
          selectedImageSrc.startsWith("https://")
        ) {
          this.CurrImage = selectedImageSrc;
        } else {
          // Remove leading slash if exists
          let dbImage = selectedImageSrc.startsWith("/")
            ? selectedImageSrc.substring(1)
            : selectedImageSrc;

          // Construct the full URL
          this.CurrImage = baseURL + dbImage;
        }
      }

      return this.CurrImage;
    },
    backToMainPage() {
      this.$emit("backtomainpage");
    },
    removeBackgroundImage() {
      this.$emit("removeBGImage");
    },
    selectedImage(image) {
      // Function to set the image source based on the condition
      const setImageSource = (sourcePath, useMobile) => {
        const targetProperty = useMobile
          ? "useMobileImageSrc"
          : this.pageDesign.pageType == "noResultPage"
          ? "noResultSrc"
          : "src";
        this.pageDesign.imageBlock[targetProperty] = sourcePath;
      };

      let sourcePath = image;

      // Modify the path based on certain conditions
      if (!this.isResultPageType && image.startsWith("upload")) {
        sourcePath = "/" + image;
      }

      setImageSource(sourcePath, this.isSeperateMobileImage);
    },
    getPosition() {
      this.pageDesign.imageBlock.style.xCord = this.tempValueX;
      this.pageDesign.imageBlock.style.yCord = this.tempValueY;
 
    },
    handleMouseMove(e) {
      let { left, top, width, height } =
        this.$refs.figureBlock.getBoundingClientRect();
      let { clientX, clientY } = e;
      const posX = clientX - left;
      const posY = clientY - top;
      let CordX = parseInt((posX / width) * 100);
      let CordY = parseInt((posY / height) * 100);

      if (CordY >= 0 && CordY <= 100 && CordX >= 0 && CordX <= 100) {
        this.tempValueX = CordX;
        this.tempValueY = CordY;
      }
    },
    setImageAlign(type) {
      if(this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView){
        this.pageDesign.pageStyles.mobileImageAlign = type
      } else {
        this.pageDesign.pageStyles.imageAlign = type
      }
    },
  },
};
</script>

<style scoped>
 .editor-select-color {
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    padding: 6px;
    gap: 10px;
  }
  .editor-color-input {
    outline: none;
    border: none;
    background: transparent;
  }
  .editor-select-color:focus-within {
    box-shadow: 0px 0px 1px 1px var(--secondary-color);
  }
.image-upload {
  height: 200px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(227, 227, 227);
  margin-bottom: 16px;
  overflow: hidden;
}
.choose-image-empty {
  padding-bottom: 25px;
  padding-top: 25px;
  background: #f6f6f7;
  border-radius: 10px;
  margin-bottom: 20px;
}
.choose-image-empty > button {
  background: white;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
}
.choose-image-empty:hover {
  border: 1px solid #7f3487;
}
.curs {
  border-radius: 6px;
}


.images-size label {
  font-size: 14px;
}
.images-size input {
  color: #535355;
}

.figureClass {
  height: 198px;
  line-height: 0;
  margin: 0px;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.figureClass >>> img {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.focalPointHover:hover {
  position: absolute;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease-in-out 0s;
}

.focal-point-picker {
  /* cursor: grab; */
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: top 0.15s ease-out 0s, left 0.15s ease-out 0s;
  background: rgb(4, 135, 175);
  box-shadow: rgb(0 0 0 / 2%) 0px 12px 16px, rgb(0 0 0 / 4%) 0px 8px 14px,
    rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 8%) 0px 2px 4px;
}
.text-align-section {
  color: #b3afb6;

  width: fit-content;
  border-radius: 6px;
}
.text-align-section label {
  padding: 6px 12px;
  border: 1px solid #d2d8e0;
  border: 1px solid transparent;
  margin: 0px;
  background: #f7f7f7;
}
.text-align-section label:hover {
  color: #000;
}
.active-editor-align {
  border-radius: 6px;
  border: 1px solid var(--secondary-color)!important;
  background: #fff;
  color: #000;
}
.select-position-span{
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
}
</style>